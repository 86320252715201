<template>
  <div id="Notification">
    <div class="card-header bg-brand px-3 py-4 rounded-t-lg">
      <p>
        <span class="brand-color-dark">Benachrichtigungen</span><br />
        Verwalte die Arten, wie wir Dich benachrichtigen sollen. Änderungen werden automatisch gespeichert. <!--Änderungen an den Benachrichtigungseinstellungen können bis zu 24 Stunden dauern, bis sie wirksam geworden sind.-->
      </p>
    </div>
    <div class="card-content">
      <div class="alert alert-default my-3 p-3 rounded-lg text-black bg-gray-300" v-show="!isTelegramActive">
        <div class="flex gap-6">
          <div class="self-center pl-3">
            <font-awesome-icon :icon="['fab', 'telegram']" class="icon text-4xl text-blue-900" />
          </div>
          <div class="">
            <div class="divide-y-2 divide-blue-900">
              <div
                class="
                  leading-6
                  space-y-4
                  sm:leading-7
                  pb-1
                "
              >
                <p class="alert-heading text-bold">
                  <b>Echtzeitbenachrichtungen</b>
                </p>
              </div>
              <div
                class="
                pt-1
                  leading-6
                  space-y-4
                  sm:leading-7
                "
              >
                <p>
                  Verknüpfe Dein Konto mit unserem <router-link to="telegram" class="link">Telegram Bot</router-link>, um Echtzeitbenachrichtungen alternativ zur E-Mail zu erhalten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <p><i>In unserer Beta-Phase sind die Einstellungsmöglichkeiten für Benachrichtigungen für zufällige Nutzer nur teilweise oder gar nicht verfügbar. Es kann vorkommen, dass wir Dich manchmal mehr oder weniger benachrichtigen. Gib uns bitte sehr gerne Feedback :)</i></p> -->

      <div class="sm:grid sm:grid-cols-2 sm:flex-none flex flex-col sm:space-y-8 space-y-0">
        <div class="mt-3">
          <p class="font-semibold">Dienstplan</p>
          <p class="mb-2">Täglicher Dienstplan</p>
          <select :disabled="isLoading" @change="setNotificationPref(2,$event)" v-model="notification_dailyshift_time" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-40 p-2.5 mb-2">
            <option value="1">13 Uhr</option>
            <option value="0">16 Uhr</option>
            <option value="2">19 Uhr</option>
          </select>
          <label class="flex items-center space-x-2"><input :disabled="isLoading" @click="setNotificationPref(0)" v-model="notification_dailyshift_mail" type="checkbox" class="flex-shrink-0 form-tick h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-800 checked:border-transparent focus:outline-none"><span>E-Mail</span></label><br>
          <label class="flex items-center space-x-2"><input @click="setNotificationPref(1)" :disabled="!isTelegramActive || isLoading" v-model="notification_dailyshift_telegram" type="checkbox" class="flex-shrink-0 form-tick h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-800 checked:border-transparent focus:outline-none"><span>Telegram</span></label>
        </div>

        <div class="sm:mt-0 mt-6">
          <p class="font-semibold">Besondere Benachrichtigungen</p>
          <label class="flex items-center space-x-2"><input :disabled="isLoading" @click="setNotificationPref(3)" v-model="notification_syncneeded" type="checkbox" class="flex-shrink-0 form-tick h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-800 checked:border-transparent focus:outline-none"><span>Hinweis zum Synchronisieren</span></label><br>
          <label class="flex  items-center space-x-2"><input :disabled="isLoading" @click="setNotificationPref(4)" v-model="notification_failedinexchange" type="checkbox" class="flex-shrink-0 form-tick h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-800 checked:border-transparent focus:outline-none"><span>Hinweis, wenn Du einen Dienst synchronisierst, der sich in der Tauschbörse befindet</span></label><br>
          <label class="flex items-center space-x-2">
            <input :disabled="isLoading" @click="setNotificationPref(5)" v-model="notification_notifyonfreedays" type="checkbox" class="flex-shrink-0 form-tick h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-800 checked:border-transparent focus:outline-none">
            <span>Benachrichtigungen an freien Tagen <span class="input-helper-text">Frei, Z-Frei</span></span>
            
          </label><br>
          <label class="flex items-center space-x-2"><input :disabled="isLoading" @click="setNotificationPref(6)" v-model="notification_notifyonvacation" type="checkbox" class="flex-shrink-0 form-tick h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-800 checked:border-transparent focus:outline-none"><span>Benachrichtigungen an Urlaubstagen</span></label>
        </div>
      </div>

      <!-- <div class="mt-3">
        <p class="pb-2 font-semibold">Board</p>
        <p class="pb-2 font-semibold">Neue Langsamfahrstelle</p>
        <label class="flex items-center space-x-2"><input type="checkbox" class="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"><span>E-Mail</span></label><br>
        <label class="flex items-center space-x-2"><input type="checkbox" class="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"><span>Telegram</span></label>
      </div> -->
     
      
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "Notification",
  components: {
  },
  data() {
    return {
      isTelegramActive: false,
      notification_dailyshift_time: 2,
      notification_dailyshift_mail: false,
      notification_dailyshift_telegram: false,
      notification_syncneeded: false,
      notification_failedinexchange: false,
      notification_notifyonfreedays: false,
      notification_notifyonvacation: false
    };
  },
  methods: {
    setNotificationPref(f,e) {

      var t = 0, v = -1;

      switch(f) {
        case 0:
          t = 0;
          v = !this.notification_dailyshift_mail;
          break

        case 1:
          t = 1;
          v = !this.notification_dailyshift_telegram;
          break

        case 2:
          t = 2;
          v = e.target.value;
          break;

        case 3:
          t = 3;
          v = !this.notification_syncneeded;
          break

        case 4:
          t = 4;
          v = !this.notification_failedinexchange;
          break

        case 5:
          t = 5;
          v = !this.notification_notifyonfreedays;
          break

        case 6:
          t = 6;
          v = !this.notification_notifyonvacation;
          break

        default:
          console.log('Unknown f');
          return
          break
      }

      this.$store.dispatch("setNotificationSettings", {
        t: t,
        v: v,
        }).then((a) => {
        console.log("setNotificationSettings");
        console.log(a);
        if (a.status) {
          this.isLoading = false;
        } else {
          alert('Es ist ein Problem beim Speichern der Einstellungen aufgetreten. Lade die Seite neu und versuche es erneut.')
        }
      });
    }
  },
  mounted() {

    this.notification_dailyshift_mail = this.user.settings_notification_dailyshift_mail;
    this.notification_dailyshift_telegram = this.user.settings_notification_dailyshift_telegram;
    this.notification_dailyshift_time = this.user.settings_notification_dailyshift_time;
    this.notification_syncneeded = this.user.settings_notification_syncneeded;
    this.notification_failedinexchange = this.user.settings_notification_failedinexchange;
    this.notification_notifyonfreedays = this.user.settings_notification_notifyonfreedays;
    this.notification_notifyonvacation = this.user.settings_notification_notifyonvacation;


    this.$store.dispatch("fetchTelegramStatusForUser").then((a) => {
      if (a.status) {
        this.isLoading = false;
        if (a.response.data.length == 0) {
        } else {
          this.isTelegramActive = true
        }
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>